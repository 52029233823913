.hide-scrollbars::-webkit-scrollbar {
    display: none;
    -webkit-scrollbar {
        display: none;
    }
}

.hide-tooltip .cds--popover-container span.cds--popover {
    display: none;
}

.cds--side-nav__header-navigation {
    margin: 0;
}

.cds--modal-close-button {
    z-index: 99999999;
}

.cds--modal-content {
    outline: 0 !important;
}

.cds--header {
    z-index: 999999;
}

#lira-settings-page-modal .cds--modal-container {
    inline-size: 100%;
}

.cds--header__nav {
    padding-left: 0;
}

.cds--header__nav::before {
    display: none !important;
}

:root, html, body {
    background-color: black;
}

/* .qc-container {
    z-index: 99999;
} */

#lira-qc-enter-fullscreen-icon, #lira-qc-exit-fullscreen-icon {
    width: 25px;
    height: 25px;
    transition: all 0.1s;
}

/* Override the default Carbon UI TableRow height minimum to 20px instead of 40px*/
#lira-advanced-routes-routes .cds--data-table .override-table-row-styles {
    height: 28px;
}

#lira-advanced-routes-routes .cds--data-table-content::-webkit-scrollbar {
    /* overflow: hidden; */
    display: none;
    -webkit-scrollbar {
        display: none;
    }
}

.no-mouse-focus:focus {
    outline: none;
}

@media (hover: hover) {
    .website-link:hover {
        color: white !important;
    }

    #nav-bar-link-advanced-routes {
        display: block;
    }

    #nav-bar-link-mobile-magnum {
        /* display: none; */
    }

    #lira-qc-enter-fullscreen-icon:hover, #lira-qc-exit-fullscreen-icon:hover {
        background-color: #6f6f6f80;
        transform: scale(1.1);
        /* width: 28px; */
        /* height: 28px; */
    }
}

@media (hover: none) {
    .website-link:hover {
        color: white !important;
    }

    #nav-bar-link-mobile-magnum {
        display: block;
    }

    /*^ -----------------------------------------------  */
    /*^ Advanced Routes Queries */
    /*^ -----------------------------------------------  */
    
    #lira-advanced-routes-edit-button {
        display: flex;
    }
}

/*^ -----------------------------------------------  */
/*^ Home and Engineering CSS */
/*^ -----------------------------------------------  */

#lira-home-container {
    height: calc(100vh - 3rem);
}

.lira-eng-home-container {
    height: calc(100vh - 3rem);
}

#playlist-background-wrapper {
    transition: background 1s; /* Added transition property */
}

.lira-schedule-container {
    height: 50vh
}

#home-page-left-panel {
    height: calc(100vh - 3rem);
}

/*^ -----------------------------------------------  */
/*^ Advanced Routes CSS */
/*^ -----------------------------------------------  */

#lira-advanced-routes-routes * {
    font-size: 12px !important;
}

#lira-advanced-routes-routes .cds--search-magnifier-icon {
    margin-left: -10px;
}

#lira-advanced-routes-edit-button {
    display: none;
}

/*^ -----------------------------------------------  */
/*^ Radio CSS */
/*^ -----------------------------------------------  */

#radio-stream-page-container {
    min-height: calc(100vh - 3rem);
}

#radio-stream-page-routes-table-body {
    max-height: 85vh;
}

.radio-stream-page-stream-text {
    padding-left: 20px;
    padding-right: 20px
}

#radio-stream-page-container .cds--search-magnifier-icon {
    margin-left: -10px;
}

/*^ -----------------------------------------------  */
/*^ NMOS CSS */
/*^ -----------------------------------------------  */

#lira-nmos-routing-buttons-container span.cds--popover-container {
    flex-grow: 1;
}

#lira-nmos-routing-buttons-container span.cds--popover-container span.cds--popover {
    display: none;
}

#lira-nmos-routing-buttons-container span.cds--popover-container div.cds--tooltip-trigger__wrapper {
    display: flex;
}

/*^ -----------------------------------------------  */
/*^ Dante CSS */
/*^ -----------------------------------------------  */

#lira-dante-page-container, #dante-multiselect-sources, .cds--tag--filter {
    /* display: none; */
}

#lira-dante-page-container, #dante-multiselect-sources, .cds--list-box__menu-item__option:before {
    /* display: none; */
}

/*^ -----------------------------------------------  */
/*^ ROKU CSS */
/*^ -----------------------------------------------  */

#roku-remote-container, #mobile-roku-remote-container {
    width: 400px;
    border: 1px solid white;
    background-color: black;
}

#roku-remote-keyboard-mobile div button {
    width: 38px
}

/*^ -----------------------------------------------  */
/*^ MONITORING CSS */
/*^ -----------------------------------------------  */

#lira-monitoring-page-container .cds--data-table-content {
    /* overflow: hidden; */
}

/*^ -----------------------------------------------  */
/*^ POLATIS CSS */
/*^ -----------------------------------------------  */

#lira-polatis-page-container .cds--data-table--sticky-header {
   max-block-size: none;
   overflow-y: hidden;
}

#lira-polatis-page-container .cds--data-table_inner-container {
   flex-grow: 1;
   background-color: transparent;
}

#lira-polatis-page-container span.cds--popover-container {
    flex-grow: 1;
}

#lira-polatis-page-container span.cds--popover-container span.cds--popover {
    display: none;
}

#lira-polatis-page-container span.cds--popover-container div.cds--tooltip-trigger__wrapper {
    display: flex;
}

/*^ -----------------------------------------------  */
/*^ INTERCOM CSS */
/*^ -----------------------------------------------  */

#lira-intercom-salvos-list-container .cds--search-magnifier-icon {
   inset-inline-start: 0;
}

#lira-intercom-salvos-viewer-container .cds--search-magnifier-icon {
    inset-inline-start: 0;
}

#lira-intercom-page-bolero-config-modal .cds--modal-content:focus {
    outline: 0
}

#lira-intercom-page-bolero-config-modal .cds--select--inline, #lira-intercom-page-bolero-config-modal .cds--select-input {
    inline-size: 25ch;
}

#lira-intercom-page-bolero-config-modal .cds--select--inline label {
    display: none;
}

#lira-intercom-page-bolero-config-modal .cds--slider-container {
    inline-size: 100%;
}

/*^ -----------------------------------------------  */
/*^ CAPTIONING CSS */
/*^ -----------------------------------------------  */

#lira-captioning-page-container .cds--data-table-content {
   width: 100%;
}

#lira-captioning-page-container .cds--popover-container {
   position: initial;
}

#lira-captioning-page-container .cds--form-item {
   flex-grow: 0;
}

#lira-captioning-models-page-container .cds--data-table--sticky-header {
    max-block-size: calc(100vh - 6rem);
    /* overflow-y: hidden; */
}

#lira-captioning-models-page-container div.cds--data-table-content:nth-of-type(1) {
    overflow-x: hidden;
}

/*^ -----------------------------------------------  */
/*^ SALVOS CSS */
/*^ -----------------------------------------------  */

#lira-salvos-page-container .cds--search-magnifier-icon {
   inset-inline-start: 0;
}

#lira-salvos-page-add-new-step-icon, #lira-salvos-page-add-new-salvo-icon, #lira-salvos-page-delete-salvo-icon {
   color: gray
}

#lira-salvos-page-add-new-step-icon:hover, #lira-salvos-page-add-new-salvo-icon:hover, #lira-salvos-page-delete-salvo-icon:hover {
   color: rgb(141, 141, 141)
}

#lira-salvos-page-container .cds--data-table--sticky-header {
    max-block-size: calc(100vh - 10rem);
    /* overflow-y: hidden; */
}

#lira-salvos-page-container .cds--data-table--sticky-header tbody {
    min-height: calc(100vh - 13rem);
    overflow: hidden;
}

#lira-salvos-page-container .cds--data-table--sticky-header tbody tr td:nth-child(1) {
    padding-block-start: 0;
    /* padding-inline-start: 0; */
}

#lira-salvos-page-container .cds--data-table--sticky-header .cds--select-input__wrapper select {
    width: 20ch;
}

#lira-salvos-page-container .lira-salvos-page-edit-salvo-name-icon {
    color: rgb(136, 136, 136);
    display: inline-block;
    position: relative;
    left: 10px;
    width: 15px;
    height: 15px;
    cursor: pointer
}

#lira-salvos-page-container .lira-salvos-page-edit-salvo-name-icon:hover {
    color: rgb(185, 157, 97);
}

#lira-salvos-page-container .cds--progress .cds--progress-label {
    text-align: left;
}

#lira-salvos-page-salvos-info-table th:first-of-type div {
    padding-left: 20px;
}

#lira-salvos-page-container .cds--expandable-row .cds--child-row-inner-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#lira-salvos-page-container .cds--table-expand {
    display: none
}

#lira-salvos-page-container .cds--select-input--inline__wrapper {
    position: relative;
    left: 15px;
}

#lira-salvos-page-container .cds--parent-row .cds--select-input--inline__wrapper {
    position: relative;
    top: -8px;
}

#lira-salvos-page-container .cds--expandable-row[data-child-row] {
    background-color: #0000004d;
}

#lira-salvos-page-container .cds--expandable-row[data-child-row]:hover {
    background: #0000004d;
}

#lira-salvos-page-container .cds--expandable-row .cds--child-row-inner-container {
    padding: 0;
}

#lira-salvos-page-container .cds--expandable-row .cds--child-row-inner-container div:first-of-type {
    position: relative;
    left: -16px;
    width: 50%;
}

#lira-salvos-page-container .cds--expandable-row .cds--child-row-inner-container div:last-of-type {
    position: relative;
    left: -4px;
    width: 50%;
}

#lira-salvos-page-modal .cds--data-table-content, #lira-salvos-page-new-salvo-helper-modal .cds--data-table-content {
   height: 50vh
}

.lira-salvos-page-delete-step-icon {
   color: firebrick
}

.lira-salvos-page-delete-step-icon:hover {
   color: rgb(205, 39, 39)
}

#lira-salvos-page-salvo-progress-dots {
    /* display: flex; */
    /* flex-direction: row; */
    /* align-items: center; */
    animation: fade-in-from-left 2s forwards infinite;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
}

/* #lira-salvos-page-salvo-progress-dots .progress-dot {
    opacity: 0;
    animation: fade-in-from-left 1s forwards;
} */

@keyframes fade-in-from-left {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    50% {
        opacity: 100;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-20px);
    }
    
}

#lira-salvos-page-new-salvo-helper-modal .cds--modal-content {
    padding: 0;
}

#lira-salvos-page-new-salvo-helper-modal .cds--btn {
    padding-inline: 0;
    display: flex;
    justify-content: center;
    max-inline-size: none;
}

/*^ -----------------------------------------------  */
/*^ CCU CSS */
/*^ -----------------------------------------------  */

#lira-ccu-rcp-page-container .cds--search-magnifier-icon {
    inset-inline-start: 0;
 }
 
 #lira-ccu-rcp-page-container .cds--data-table--sticky-header {
     max-block-size: calc(100vh - 10rem);
     /* overflow-y: hidden; */
 }
 
 #lira-ccu-rcp-page-container .cds--data-table--sticky-header tbody {
     min-height: calc(100vh - 13rem);
     overflow: hidden;
 }
 
 #lira-ccu-rcp-page-container .cds--data-table--sticky-header tbody tr td:nth-child(1) {
     padding-block-start: 0;
     /* padding-inline-start: 0; */
 }
 
 #lira-ccu-rcp-page-container .cds--data-table--sticky-header .cds--select-input__wrapper select {
     width: 20ch;
 }
 
 #lira-ccu-rcp-page-container .lira-salvos-page-edit-salvo-name-icon {
     color: rgb(136, 136, 136);
     display: inline-block;
     position: relative;
     left: 10px;
     width: 15px;
     height: 15px;
     cursor: pointer
 }
 
 #lira-ccu-rcp-page-container .lira-salvos-page-edit-salvo-name-icon:hover {
     color: rgb(185, 157, 97);
 }
 
 #lira-ccu-rcp-page-container .cds--progress .cds--progress-label {
     text-align: left;
 }
 
 /* #lira-salvos-page-salvos-info-table th:first-of-type div {
     padding-left: 20px;
 } */
 
 #lira-ccu-rcp-page-container .cds--expandable-row .cds--child-row-inner-container {
     display: flex;
     flex-direction: column;
     width: 100%;
 }
 
 #lira-ccu-rcp-page-container .cds--table-expand {
     display: none
 }
 
 #lira-ccu-rcp-page-container .cds--select-input--inline__wrapper {
     position: relative;
     left: 15px;
 }
 
 #lira-ccu-rcp-page-container .cds--parent-row .cds--select-input--inline__wrapper {
     position: relative;
     top: -8px;
 }
 
 #lira-ccu-rcp-page-container .cds--expandable-row[data-child-row] {
     background-color: #0000004d;
 }
 
 #lira-ccu-rcp-page-container .cds--expandable-row[data-child-row]:hover {
     background: #0000004d;
 }
 
#lira-ccu-rcp-page-container .cds--expandable-row .cds--child-row-inner-container tr {
    position: relative;
    left: -20px;
    width: 100%;
    display: flex;
}

/* #lira-ccu-rcp-page-container .cds--expandable-row .cds--child-row-inner-container tr div {
    position: relative;
} */
 
 #lira-ccu-rcp-page-container .cds--expandable-row .cds--child-row-inner-container div:first-of-type {
     position: relative;
     left: -16px;
     width: 50%;
 }
 
 #lira-ccu-rcp-page-container .cds--expandable-row .cds--child-row-inner-container div:last-of-type {
     position: relative;
     left: -4px;
     width: 50%;
 }
 
 /* #lira-salvos-page-modal .cds--data-table-content, #lira-salvos-page-new-salvo-helper-modal .cds--data-table-content {
    height: 50vh
 } */

#lira-ccu-rcp-page-container span.cds--popover-container {
    flex-grow: 1;
}

 #lira-ccu-rcp-page-container span.cds--popover-container span.cds--popover {
    display: none;
}

 #lira-ccu-rcp-page-container span.cds--popover-container div.cds--tooltip-trigger__wrapper {
    display: flex;
}

/*^ -----------------------------------------------  */
/*^ OMNi ROUTING CSS */
/*^ -----------------------------------------------  */

#lira-omni-routing-page-container span.cds--popover-container {
    flex-grow: 1;
}

 #lira-omni-routing-page-container span.cds--popover-container span.cds--popover {
    display: none;
}

#lira-omni-routing-page-container span.cds--popover-container div.cds--tooltip-trigger__wrapper {
    display: flex;
}

#lira-omni-router-table-container {
    grid-template-columns: 1fr 0.3fr;
}

#mobile-lira-omni-router-pending-changes-container {
    display: none;
}

#mobile-lira-omni-router-pending-changes-modal-button-icon {
    display: none
}

@media (hover: none) {
    #lira-omni-router-table-container {
        grid-template-columns: 1fr;
    }
    #lira-omni-router-pending-changes-container {
        display: none;
    }

    #mobile-lira-omni-router-pending-changes-container {
        display: flex;
    }

    #mobile-lira-omni-router-pending-changes-modal-button-icon {
        display: flex;
    }
}


/*TODO ----------------------------------------------------------- */
/*TODO ----------------------------------------------------------- */
/*TODO ----------------------------------------------------------- */

@media screen and (hover: hover) and (pointer: coarse) {
    .radio-stream-page-stream-container {
        display: flex;
        flex-direction: column;
        min-height: 150px;
        justify-content: center;
        align-items: center;
    }
}

/*TODO ----------------------------------------------------------- */
/*TODO ----------------------------------------------------------- */
/*TODO ----------------------------------------------------------- */

@media only screen and (max-width: 1200px) {
    .advanced-routing-search-field {
        flex-direction: column;   
        justify-content: space-between;
        align-items: space-between; 
    }
}

@media only screen and (hover:none) and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2),
       only screen and (hover:none) and (max-width: 767px) and (min-resolution: 192dpi),
         only screen and (max-width: 767px) and (max-height: 800px)
  /* Styles for iPhones and high-density Android devices in portrait mode */
/** Phone Generic  */
/* @media only screen  */
    /* and (hover: none) */
    /* and (width: 390px)  */
    /* and (height: 844px)  */
    /* and (-webkit-device-pixel-ratio: 3) */
     {

    /*^ -----------------------------------------------  */
    /*^ Home and Engineering Mobile Queries */
    /*^ -----------------------------------------------  */

    #lira-home-container {
        height: 80vh;
        /* border: 1px solid red; */
    }
    
    #home-page-left-panel {
        height: 80vh;
    }

    .lira-schedule-container {
        height: 210px;
        /* border: 1px solid #ccc; */
    }

    .lira-eng-home-container {
        flex-direction: column;
    }
    
    .lira-eng-home-container #home-page-left-panel {
        height: 20vh;
        /* border: 1px solid red; */
    }

    .lira-eng-home-container .lira-schedule-container {
        display: none
    }

    .lira-eng-home-container #home-page-right-panel {
        /* display: none; */
        /* margin: 0 auto; */
        /* border: 1px solid red; */
        width: 100vw;
        max-height: 62vh;
    }

    /* #eng-dynamic-pane-buttons {
        display: none;
    }

    #mobile-eng-dynamic-pane-buttons {
        display: flex;
    } */
    
    /*^ -----------------------------------------------  */
    /*^ Advanced Routes Queries */
    /*^ -----------------------------------------------  */
    
    #lira-advanced-routes-edit-button {
        display: flex;
    }

    /*^ -----------------------------------------------  */
    /*^ Mobile Magnum Queries */
    /*^ -----------------------------------------------  */

    #lira-magnum {
        width: 100vw;
    }

    /*^ -----------------------------------------------  */
    /*^ Radio Mobile Queries */
    /*^ -----------------------------------------------  */

    #radio-stream-page-container {
        min-height: calc(80vh - 3rem);
        /* border: 1px solid red; */
    }

    /* #radio-stream-page-routes-container {

    } */

    #radio-stream-page-routes-table-body {
        height: 78vh;
    }

    #radio-stream-players-container {
        max-height: 84vh;
        min-width: 0;
        width: 300px;
        overflow-x: hidden;
        overflow-y: scroll;
        /* border: 1px solid orange */
    }

    /* .radio-stream-page-stream-container {
        display: block;
    } */

    .radio-stream-page-stream-title {
        font-size: 16px;
        padding-bottom: 10px;
    }

    .radio-stream-page-search-routes-container {
        margin-top: 20px
    }

    .radio-stream-page-stream-text {
        padding-left: 40px;
        padding-right: 40px
    }

    .radio-stream-page-search-routes-container .cds--search-input {
        max-width: 200px;
        margin: 0 auto;
        max-width: 180px;
        border-radius: 0px !important;
    }

    /*^ -----------------------------------------------  */
    /*^ NMOS Mobile Queries */
    /*^ -----------------------------------------------  */

    #nmos-routing-page-container {
        min-height: 80vh
    }
}

/** Phone Landscape  */
/* @media only screen and (orientation: landscape) and (hover:none) and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2),
       only screen and (orientation: landscape) and (hover:none) and (min-width: 768px) and (min-resolution: 192dpi) { */

@media only screen and (hover:none) and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2),
    only screen and (hover:none) and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape),
    
    /** SAMSUNG GALAXY S24 ULTRA LANDSCAPE QUERY */
    only screen and (min-width: 768px) and (max-width: 1023px) and (max-height: 400px) and (orientation: landscape)
    {
   /* Styles for devices like Samsung Galaxy S22 in landscape mode */
  /* Styles for iPhones and high-density Android devices in landscape mode */
/* @media only screen  */
    /* and (hover: none) */
    /* and (width: 390px)  */
    /* and (height: 844px)  */
    /* and (orientation: landscape) */
    /* and (-webkit-device-pixel-ratio: 3) { */

    /*^ -----------------------------------------------  */
    /*^ Home and Engineering Mobile Queries */
    /*^ -----------------------------------------------  */

    #home-page-right-panel {
        display: none;
    }

    #lira-home-container {
        /* height: 60vh; */
        /* border: 1px solid red; */
    }
    
    .lira-eng-home-container #home-page-left-panel {
        /* height: 60vh; */
        /* border: 1px solid red; */
    }

    .lira-eng-home-container {
        flex-direction: column;
        /* height: 60vh; */
        /* border: 1px solid red;  */
    }

    .lira-schedule-container {
        display: none
    }

    /*^ -----------------------------------------------  */
    /*^ Advanced Routes Queries */
    /*^ -----------------------------------------------  */
    
    #lira-advanced-routes-edit-button {
        display: flex;
    }
}

/** iPad 11in Pro Generic */
@media only screen and (min-width: 1024px) 
and (hover: none)
and (max-height: 1366px) 
and (-webkit-min-device-pixel-ratio: 1.5) {
    
    /*^ -----------------------------------------------  */
    /*^ Home and Engineering Mobile Queries */
    /*^ -----------------------------------------------  */

    #lira-home-container {
        height: 85vh;
        /* border: 1px solid red; */
    }

    #home-page-left-panel {
        height: 85vh;
    }

    .lira-schedule-container {
        /* height: 210px; */
        /* border: 1px solid #ccc; */
    }

    .lira-eng-home-container {
        height: 85vh;
        /* border: 1px solid red; */
    }

    /*^ -----------------------------------------------  */
    /*^ Mobile Magnum Queries */
    /*^ -----------------------------------------------  */

    #lira-magnum {
        /* width: 100vw; */
    }

    /*^ -----------------------------------------------  */
    /*^ Radio Mobile Queries */
    /*^ -----------------------------------------------  */

    #radio-stream-page-container {
        min-height: calc(80vh - 3rem);
        /* border: 1px solid red; */
    }

    /* #radio-stream-page-routes-container {

    } */

    #radio-stream-page-routes-table-body {
        height: 78vh;
    }

    #radio-stream-players-container {
        max-height: 84vh;
        min-width: 0;
        width: 300px;
        overflow-x: hidden;
        overflow-y: scroll;
        /* border: 1px solid orange */
    }

    /* .radio-stream-page-stream-container {
        display: block;
    } */

    .radio-stream-page-stream-title {
        font-size: 16px;
        padding-bottom: 10px;
    }

    .radio-stream-page-search-routes-container {
        margin-top: 20px
    }

    .radio-stream-page-stream-text {
        padding-left: 40px;
        padding-right: 40px
    }

    .radio-stream-page-search-routes-container .cds--search-input {
        max-width: 200px;
        margin: 0 auto;
        max-width: 180px;
        border-radius: 0px !important;
    }

    /*^ -----------------------------------------------  */
    /*^ NMOS Mobile Queries */
    /*^ -----------------------------------------------  */

    #nmos-routing-page-container {
        min-height: 80vh
    }
    
    /*^ -----------------------------------------------  */
    /*^ ROKU Mobile Queries */
    /*^ -----------------------------------------------  */

    #mobile-roku-remote-container {
        position: relative;
        top: -50px;
        transform: scale(.95);
    }
}

/** iPad 11in Pro Portrait */
@media only screen 
  and (min-width: 820px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
   
    /*^ -----------------------------------------------  */
    /*^ Home and Engineering Mobile Queries */
    /*^ -----------------------------------------------  */

    #lira-home-container {
        height: 90vh;
        /* border: 1px solid red; */
    }

    #home-page-left-panel {
        height: 90vh;
    }

    #lira-home-container .lira-schedule-container {
        /* height: 80vh; */
        /* border: 1px solid #ccc; */
    }

    .lira-eng-home-container .lira-schedule-container {
        display: none;
        /* height: 210px; */
        /* border: 1px solid #ccc; */
    }

    .lira-eng-home-container {
        flex-direction: column;
        /* border: 1px solid red; */
    }

    .lira-eng-home-container #home-page-left-panel {
        /* display: none; */
        max-height: 50vh;
    }

    #home-page-right-panel {
        /* display: flex; */
        margin: 0 auto;
        width: 62vw;
        max-height: 40vh;
    }

    /*^ -----------------------------------------------  */
    /*^ Radio Mobile Queries */
    /*^ -----------------------------------------------  */

    #radio-stream-page-container {
        min-height: calc(80vh - 3rem);
        /* border: 1px solid red; */
    }

    /* #radio-stream-page-routes-container {

    } */

    #radio-stream-page-routes-table-body {
        height: 78vh;
    }

    #radio-stream-players-container {
        max-height: 84vh;
        min-width: 0;
        width: 300px;
        overflow-x: hidden;
        overflow-y: scroll;
        /* border: 1px solid orange */
    }

    /* .radio-stream-page-stream-container {
        display: block;
    } */

    .radio-stream-page-stream-title {
        font-size: 16px;
        padding-bottom: 10px;
    }

    .radio-stream-page-search-routes-container {
        margin-top: 20px
    }

    .radio-stream-page-stream-text {
        padding-left: 40px;
        padding-right: 40px
    }

    .radio-stream-page-search-routes-container .cds--search-input {
        max-width: 200px;
        margin: 0 auto;
        max-width: 180px;
        border-radius: 0px !important;
    }

    /*^ -----------------------------------------------  */
    /*^ NMOS Mobile Queries */
    /*^ -----------------------------------------------  */

    #nmos-routing-page-container {
        min-height: 80vh
    }

    /*^ -----------------------------------------------  */
    /*^ ROKU Mobile Queries */
    /*^ -----------------------------------------------  */

    #mobile-roku-remote-container {
        position: relative;
        top: -220px;
        transform: scale(.6);
    }
}