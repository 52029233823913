body {
    overscroll-behavior: none;
}

#lira-schedule-main-schedule-container .cds--data-table-content {
    scrollbar-width: none;
}

#lira-schedule-main-schedule-container .cds--data-table-content::-webkit-scrollbar {
    display: none;
    -webkit-scrollbar {
        display: none;
    }
}

#lira-schedule-page #lira-schedule-todays-events-container {
    scrollbar-width: none;
}

#lira-schedule-page #lira-schedule-todays-events-container .cds--tile {
    scrollbar-width: none;
}

#lira-schedule-page #lira-schedule-todays-events-container::-webkit-scrollbar {
    display: none;
    -webkit-scrollbar {
        display: none;
    }
}

#lira-schedule-page #lira-schedule-todays-events-container .cds--tile::-webkit-scrollbar {
    display: none;
    -webkit-scrollbar {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .lira-eng-home-container #home-page-left-panel #lira-schedule-main-schedule-container {
        display: none;
    }
   
    .lira-eng-home-container #home-page-right-panel div[style*="box-shadow"] {
        display: none;
    }
}

@media screen and (hover: none) {
    #lira-schedule-todays-events-mobile {
        display: none;
    }
}


.cds--modal-tall {
    z-index: 999999999999;
}

.cds--modal {
    z-index: 99999;
    /* background-color: transparent; */
}

.flatpickr-calendar.open {
    z-index: 9999999999;
    background-color: rgb(72,72,72);
}

.flatpickr-calendar.open .flatpickr-months .flatpickr-prev-month {
    background-color: rgb(72,72,72);
    stroke: white;
}

.flatpickr-calendar.open .flatpickr-months .flatpickr-next-month {
    background-color: rgb(72,72,72);
    stroke: white;
}

.flatpickr-calendar.open .flatpickr-months .flatpickr-month .flatpickr-current-month .cur-month {
    color: white;
}

.flatpickr-calendar.open .flatpickr-months .flatpickr-month .flatpickr-current-month .cur-month:hover {
    background-color: rgb(72,72,72);
    color: white;
}

.flatpickr-calendar.open .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .numInput {
    background-color: rgb(72,72,72);
    color: white;
    /* border: 2px solid black; */
}

.flatpickr-calendar.open .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowUp::after {
    border-block-end-color: white;
    border-block-start-color: white;
}

.flatpickr-calendar.open .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowDown::after {
    border-block-end-color: white;
    border-block-start-color: white;
}

.flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-weekdays .flatpickr-weekdaycontainer .flatpickr-weekday {
    color: white;
}

.flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day.prevMonthDay {
    color: gray;
}

.flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day {
    color: white;
}
.flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day.nextMonthDay {
    color: gray;
}
.flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day.today::after {
    background-color: white;
}
.flatpickr-calendar.open .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day.selected {
    background-color: rgb(35, 35, 35);
    outline: 1px solid white
}

.enter-full-screen-icon, 
.exit-full-screen-icon {
    color: gray;
}

.enter-full-screen-icon:hover, 
.exit-full-screen-icon:hover {
    color: rgb(168, 168, 168);
    
}

#lira-schedule-new-event-selects-remove-icon {
    color: gray;
}

#lira-schedule-new-event-selects-remove-icon:hover {
    color: red;
}

#lira-schedule-main-schedule-container #create-event-icon, 
#lira-schedule-main-schedule-container #edit-event-icon,
#lira-schedule-main-schedule-container #duplicate-event-icon, 
#lira-schedule-main-schedule-container #delete-event-icon {
    color: gray;
}

#lira-schedule-main-schedule-container #create-event-icon:hover, 
#lira-schedule-main-schedule-container #edit-event-icon:hover, 
#lira-schedule-main-schedule-container #duplicate-event-icon:hover, 
#lira-schedule-main-schedule-container #delete-event-icon:hover {
    color: rgb(141, 141, 141)
}

#lira-schedule-top-half-container #lira-schedule-todays-events-container {
    max-height: calc(53vh - 3rem);
    overflow: scroll;
}

#lira-schedule-top-half-container #lira-schedule-todays-events-container div.cds--tile {
    overflow: scroll;
}

#lira-schedule-main-schedule-container #lira-schedule-todays-events-container .cds--data-table-content {
    width: 50vw;
    outline: 0;
    height: calc(53vh - 3rem);
    background-color: #393939;
}

#lira-schedule-main-schedule-container #lira-schedule-todays-events-container div.cds--data-table-content tbody .start-time-display-cell input.cds--text-input {
    outline: 0;
    border: 1px solid rgb(80, 80, 80);
    background-color: transparent;
    height: 47px;
    margin: 0;
    color: rgb(200, 200, 200);
}

#lira-schedule-todays-events-container .lira-schedule-todays-events-card {
    width: 120%;
}

#lira-schedule-main-schedule-container .cds--data-table-content {
    width: fit-content;
    outline: 0;
}

#lira-schedule-main-schedule-container .cds--search-close {
    height: 48px;
}

#lira-schedule-main-schedule-container .cds--toolbar-search-container-active.cds--search {
    /* inline-size: 95%; */
    inline-size: 100%;
}

#lira-schedule-main-schedule-container #event-time-container .cds--checkbox-wrapper {
    position: relative;
    top: 14px;
}

#lira-schedule-main-schedule-container div.cell-in-editing input.cds--text-input, 
#lira-schedule-main-schedule-container div.cell-in-editing input.cds--date-picker__input, 
#lira-schedule-main-schedule-container div.cell-in-editing select.cds--select-input {
    border: 0;
    outline: 1px solid rgb(168, 168, 168);
    background-color: #484848;
    height: 47px;
    margin: 0;
}
#lira-schedule-main-schedule-container div.cds--modal-content div.cell-in-editing input.cds--text-input, 
#lira-schedule-main-schedule-container div.cds--modal-content div.cell-in-editing input.cds--date-picker__input, 
#lira-schedule-main-schedule-container div.cds--modal-content div.cell-in-editing select.cds--select-input {
    border: 0;
    outline: 1px solid rgb(168, 168, 168);
    background-color: #484848;
    height: 51px;
    margin: 0;
    position: relative;
    top: 2px
}
#lira-schedule-main-schedule-container div.cell-in-editing div.cds--multi-select {
    border: 0;
    outline: 1px solid rgb(168, 168, 168);
    background-color: #484848;
    height: 47px;
    margin: 0;
    position: relative;
    top: -7px;
    left: 2px;
}

#lira-schedule-main-schedule-container td {
    color: inherit
}


#lira-schedule-main-schedule-container td input {
    color: inherit
}

#lira-schedule-main-schedule-container td select {
    color: inherit
}

#lira-schedule-main-schedule-container #lira-schedule-main-container div.cds--search {
    position: sticky;
    left: 0;
    /* flex-shrink: 0; */
}

#lira-schedule-main-schedule-container #lira-schedule-main-container table.cds--data-table thead {
    position: sticky;
    top: 0;
    z-index: 2;
}

#lira-schedule-main-schedule-container #lira-schedule-main-container div.cds--data-table-content {
    /* height: calc(66vh - 6rem); */
    max-width: 100vw;
    overflow-y: scroll;
    overscroll-behavior-y: none;
}

#lira-schedule-main-schedule-container #lira-schedule-main-container div.cds--data-table-content tbody td {
    padding: 0;
    outline: 0;
    border: 0;
}

#lira-schedule-main-schedule-container #lira-schedule-main-container div.cds--data-table-content tbody .start-time-display-cell input.cds--text-input {
    outline: 0;
    border: 1px solid rgb(80, 80, 80);
    background-color: transparent;
    height: 47px;
    margin: 0;
    color: rgb(200, 200, 200);
}

#lira-schedule-main-schedule-container #lira-schedule-main-container div.cds--data-table-content tbody span .cds--popover > .cds--popover-caret {
    display: none;
}

#lira-schedule-main-schedule-container #lira-schedule-main-container div.cds--data-table-content tbody .cell-in-editing input.cds--text-input {
    outline: 0;
    border: 1px solid rgb(125, 125, 125);
    background-color: transparent;
}

#lira-schedule-main-schedule-container #lira-schedule-main-container div.cds--data-table-content tbody .cell-in-editing select.cds--select-input {
    outline: 0;
    border: 1px solid rgb(125, 125, 125);
    background-color: transparent;
}

#lira-schedule-main-schedule-container #lira-schedule-main-container div.cds--data-table-content tbody .cell-in-editing select.cds--select-input ~ svg {
    display: none;
    fill: black
}

#lira-schedule-main-schedule-container #lira-schedule-main-container div.cds--data-table-content tbody .cell-in-editing select.cds--select-input:hover ~ svg {
    display: block;
}


#lira-schedule-main-schedule-container #lira-schedule-main-container div.cds--data-table-content tbody .cell-in-editing input.cds--date-picker__input {
    outline: 0;
    border: 1px solid rgb(125, 125, 125);
    background-color: rgb(72,72,72);
    block-size: calc(48px - 1px);
    background-color: transparent;
}

#lira-schedule-main-schedule-container div.cell-in-editing div.cds--select label {
    display: none;
}


#lira-schedule-main-schedule-container #lira-schedule-main-container .schedule-table-header {
    cursor: pointer;
}

#lira-schedule-overflow-menu-tooltip {
    z-index: 1;
}

#lira-schedule-main-schedule-container #lira-schedule-customize-columns-tooltip .cds--popover-content,
#lira-schedule-main-schedule-container #lira-schedule-customize-columns-tooltip .cds--popover-caret,
#lira-schedule-main-schedule-container #lira-schedule-toggle-row-colors-tooltip .cds--popover-content,
#lira-schedule-main-schedule-container #lira-schedule-toggle-row-colors-tooltip .cds--popover-caret,
#lira-schedule-main-schedule-container #lira-schedule-overflow-menu-tooltip .cds--popover-content,
#lira-schedule-main-schedule-container #lira-schedule-overflow-menu-tooltip .cds--popover-caret,
.cds--popover-content,
.cds--popover-caret {
    background: rgb(35, 35, 35) !important;
    color: white !important;
}

#lira-schedule-main-schedule-container #lira-schedule-overflow-menu svg {
    scale: 2;
    fill: rgb(170,170,170);
}

#lira-schedule-main-schedule-container #lira-schedule-star-overflow-menu svg {
    scale: 1.5;
    fill: rgb(170,170,170);
}

#lira-schedule-main-schedule-container #lira-schedule-overflow-menu svg:hover {
    fill: rgb(232, 232, 232);
}

/* .cds--overflow-menu:hover {
    background: transparent;
}

.cds--overflow-menu:focus {
    outline: 0;
    box-shadow: none;
} */

.cds--overflow-menu-options {
    background: #393939;
    color: white;
}
.cds--overflow-menu-options:hover {
    background: #4a4a4a;
    color: white;
}

.cds--overflow-menu-options .cds--overflow-menu-options__option:hover {
    background: transparent;
    color: white;
}

.cds--overflow-menu-options .cds--overflow-menu-options__btn {
    color: white;
}

.cds--overflow-menu-options .cds--overflow-menu-options__btn:hover {
    background-color:#414141;
    color: white;
}

.cds--overflow-menu-options .cds--overflow-menu-options__btn:focus {
    outline-color: white;
    color: white;
}

#lira-schedule-ticket-generation-mobile-3rem-margin-top {
    display: none;
}

.schedule-configure-favorites-tile input.cds--text-input {
    padding-left: 8px;
}

@media screen and (max-width: 780px) {
    #lira-schedule-main-schedule-container #schedule-searchable-headers {
        display: none;
    }

    #lira-schedule-top-half-container #lira-schedule-todays-events-container h2 {
        font-size: 14px;
    }
}

@media screen and (max-width: 675px) {
    #lira-schedule-ticket-generation-mobile-3rem-margin-top {
        display: block;
    }
    #lira-schedule-todays-events-container .lira-schedule-todays-events-card {
        width: 140%;
    }
}
